import { Box } from '@chakra-ui/react';
import * as React from 'react';

import { WLText } from '../../../design-library';
import * as Styles from './Callout.module.scss';
interface CalloutProps {
  svg: React.ReactElement;
  caption: string | null | undefined;
  superscript?: string | number;
}

const Callout: React.FC<CalloutProps> = ({ caption, superscript, svg }) => {
  return (
    <Box className={Styles.CalloutContainer}>
      <Box className={Styles.Callout}>{svg}</Box>
      <WLText className={Styles.Caption}>
        {caption}
        <sup style={{ fontWeight: 'regular', fontSize: 'small' }}>
          {superscript && superscript}
        </sup>
      </WLText>
    </Box>
  );
};

export default Callout;
