import { Box, LinkBox, LinkOverlay } from '@chakra-ui/react';
import * as React from 'react';

import * as Styles from './Bevel.module.scss';

interface BevelProps {
  href?: string;
}

const Bevel: React.FC<BevelProps> = ({ children, href }) => {
  return (
    <Box as={href ? LinkBox : 'div'} className={Styles.Bevel} role="group">
      {!href ? children : <LinkOverlay href={href}>{children}</LinkOverlay>}
    </Box>
  );
};

export default Bevel;
