import { Box } from '@chakra-ui/react';
import * as React from 'react';

import { LandingPageTemplateFragment } from '../../../../graphql-types';
import { LeadFormMode } from '../../../components/Forms/LeadGen/types';
import { WLText } from '../../../design-library';
import FormAdapter from '../../FormAdapter';
import * as Styles from './Form.module.scss';
const LeadForm = React.lazy(() => import('../../Forms/LeadGen/LeadForm'));

const getHeading = () => <React.Fragment />;

const getDisclosures = () => {
  return (
    <Box marginTop={'8px'}>
      <strong>All products may not be available in all states.</strong>
      <br />
      <WLText>
        This is a solicitation of insurance and an agent may contact you. By
        submitting the above request, I consent to receive phone calls from a
        licensed insurance producer on behalf of Woodmen of the World Life
        Insurance Society (WoodmenLife) and its affiliates at the phone
        number(s) above regarding WoodmenLife products and services. I
        understand that I can contact a Sales Representative directly to make a
        purchase.
      </WLText>
    </Box>
  );
};

interface FormProps {
  hero: React.ReactElement;
  paragraph1?: React.ReactElement | string;
  paragraph2?: React.ReactElement | string;
  refPage?: string;
  subRef?: string | undefined;
  data?: LandingPageTemplateFragment;
}

const getSubmitLabel = () => 'Submit';
const Form: React.FC<FormProps> = ({
  hero,
  paragraph1,
  paragraph2,
  refPage,
  subRef,
  data,
}) => {
  return (
    <Box className={Styles.Container}>
      <Box className={Styles.HeroBox}>
        {hero}
        <Box className={Styles.TextBox}>
          <WLText>{paragraph1 && paragraph1}</WLText>
          <WLText>{paragraph2 && paragraph2}</WLText>
        </Box>
      </Box>
      <Box id="quote" className={Styles.FormBox} scrollMarginTop={'160px'}>
        {data?.callToActionForm ? (
          <FormAdapter data={data.callToActionForm} />
        ) : (
          <React.Suspense fallback={<></>}>
            <LeadForm
              mode={LeadFormMode.INDIVIDUAL}
              compact={false}
              refPage={refPage || ''}
              subRef={subRef}
              heading={getHeading}
              disclosures={getDisclosures}
              submitLabel={getSubmitLabel}
            />
          </React.Suspense>
        )}
      </Box>
    </Box>
  );
};

export default Form;
