import { Box } from '@chakra-ui/react';
import * as React from 'react';

import { WLH1, WLH2, WLH3, WLText } from '../../../design-library';
import * as Styles from './Marquee.module.scss';

interface MarqueeProps {
  hero: React.ReactElement;
  feature: {
    heading1: string | null | undefined;
    heading2?: string | null | undefined;
    heading3?: string | null | undefined;
    text?: string | null | undefined;
  };
}

const Marquee: React.FC<MarqueeProps> = ({ feature, hero }) => {
  return (
    <Box className={Styles.MarqueeContainer}>
      {hero}
      <Box className={Styles.MarqueeContent}>
        <Box className={Styles.FeatureBoxOne}>
          <WLH1>{feature.heading1}</WLH1>
          <WLH2>{feature.heading2}</WLH2>
        </Box>
        <hr style={{ height: '1px', backgroundColor: '#212121' }} />
        <Box className={Styles.FeatureBoxTwo}>
          {feature.heading3 && ( <WLH3>{feature.heading3}</WLH3> )}
          <WLText>{feature.text}</WLText>
        </Box>
      </Box>
    </Box>
  );
};

export default Marquee;
