import { RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import React, { Suspense } from 'react';

import { FormReferenceFragment } from '../../../graphql-types';
import ContentfulUtils from '../../utils/contentful.utils';
import { LeadFormMode } from '../Forms/LeadGen/types';
import RichText from '../RichText';
import RichTextV2 from '../RichTextV2';

const ContactUsForm = React.lazy(
  () => import('../Forms/ContactUs/ContactUsForm'),
);
const EmailSignupForm = React.lazy(
  () => import('../Forms/EmailSignup/EmailSignupForm'),
);
const FlagRequestForm = React.lazy(
  () => import('../Forms/FlagRequest/FlagRequestForm'),
);
const LeadForm = React.lazy(() => import('../Forms/LeadGen/LeadForm'));
const ScholarshipApplyForm = React.lazy(
  () => import('../Forms/Scholarship/Apply/ScholarshipApplyForm'),
);
const StudentLoanForm = React.lazy(
  () => import('../Forms/StudentLoan/StudentLoanForm'),
);
const UploadPhotosForm = React.lazy(
  () => import('../Forms/UploadPhotosForm/UploadPhotosForm'),
);

interface FormAdapterProps {
  data: FormReferenceFragment;
  compact?: boolean;
}

const FormAdapter: React.FC<FormAdapterProps> = ({ data, compact = false }) => {
  if (!data) {
    return null;
  }

  const refPage = data.refPage ?? '';
  const heading = data.leadFormHeading ? (
    <RichTextV2 value={data.leadFormHeading as RenderRichTextData<never>} />
  ) : (
    <React.Fragment />
  );
  const disclosures = data.leadFormDisclosures ? (
    <RichText value={data.leadFormDisclosures as RenderRichTextData<never>} />
  ) : (
    <React.Fragment />
  );
  const submitLabel =
    ContentfulUtils.getLongTextValue(data, 'leadFormSubmitLabel') || 'Submit';

  const requestedForm = data.form?.toUpperCase();
  if (requestedForm) {
    if (requestedForm === 'FLAG_REQUEST') {
      return (
        <Suspense fallback={<></>}>
          <FlagRequestForm />
        </Suspense>
      );
    } else if (requestedForm === 'CONTACT') {
      return (
        <Suspense fallback={<></>}>
          <ContactUsForm showComments />
        </Suspense>
      );
    } else if (
      requestedForm === 'PRODUCT_LEAD' ||
      requestedForm === 'SUPPORT_LEAD'
    ) {
      return (
        <Suspense fallback={<></>}>
          <LeadForm
            mode={LeadFormMode.INDIVIDUAL}
            compact={compact}
            refPage={refPage}
            heading={() => heading}
            disclosures={() => disclosures}
            submitLabel={() => submitLabel}
          />
        </Suspense>
      );
    } else if (requestedForm === 'RECRUITING_LEAD') {
      return (
        <Suspense fallback={<></>}>
          <LeadForm
            mode={LeadFormMode.RECRUITING}
            compact={compact}
            refPage={refPage}
            heading={() => heading}
            disclosures={() => disclosures}
            submitLabel={() => submitLabel}
          />
        </Suspense>
      );
    } else if (requestedForm === 'EMAIL_SIGNUP') {
      return (
        <Suspense fallback={<></>}>
          <EmailSignupForm />
        </Suspense>
      );
    } else if (requestedForm === 'PHOTO_UPLOAD') {
      return (
        <Suspense fallback={<></>}>
          <UploadPhotosForm
            photoReleaseFormUrl={
              ContentfulUtils.getAssetInfo(data.documentRef)?.url || '#'
            }
          />
        </Suspense>
      );
    } else if (requestedForm === 'STUDENT_LOAN_APPLY') {
      return (
        <Suspense fallback={<></>}>
          <StudentLoanForm />
        </Suspense>
      );
    } else if (requestedForm === 'SCHOLARSHIP_APPLY') {
      return (
        <Suspense fallback={<></>}>
          <ScholarshipApplyForm />
        </Suspense>
      );
    }
  }

  return null;
};

export default FormAdapter;
