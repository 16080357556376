import { Box } from '@chakra-ui/react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';

import ConvertHero from '../../../../static/convert/img/term-to-perm-hero.jpg';
import Protection from '../../../../static/convert/svg/Graphic-1.inline.svg';
import Cash from '../../../../static/convert/svg/Graphic-2.inline.svg';
import Coverage from '../../../../static/convert/svg/Graphic-3.inline.svg';
import { Bevel, Callout, Form, Marquee } from '../../../components/Convert';
import Layout from '../../../components/Layout';
import PageIdentifier from '../../../components/PageIdentifier';
import { WLContainer, WLH3, WLText } from '../../../design-library';
import * as Styles from './index.module.scss';

interface ConvertProps extends PageProps<undefined> {}

const ConvertPage: React.FC<ConvertProps> = ({ location }) => {
  const [isDesktop, setDesktop] = useState(
    typeof window !== `undefined` ? window.innerWidth >= 1150 : null
  );

  const updateMedia = () => {
    setDesktop(
      typeof window !== `undefined` ? window.innerWidth >= 1150 : null
    );
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <Layout
      seo={{
        title: 'Converting to Permanent Coverage May Be Right For You',
        description:
          'Learn how you can convert your WoodmenLife term policy to permanent coverage and know your family will always be financially protected.',
        slug: '/landing/convert'
      }}
      currentPath={location.pathname}
    >
      <WLContainer className={Styles.Container} width="WIDE">
        <Marquee
          hero={
            <StaticImage
              src="../../../../static/convert/img/term-to-perm-hero.jpg"
              alt="A family of four posing in front of their ranch style home"
              placeholder="blurred"
              layout="constrained"
              transformOptions={{
                fit: 'cover',
                cropFocus: 'attention'
              }}
            />
          }
          feature={{
            heading1: 'Your love for your family lasts forever,',
            heading2: 'but your term life policy won’t.',
            text: 'When you purchased a term life policy from WoodmenLife, you made a good decision to ensure your family’s financial future is protected. However, as your life changes, your life insurance needs can too. We’re here for you every step of the way.'
          }}
        />
        <Box className={Styles.PaddingBox}>
          <Box className={Styles.BevelBox}>
            {isDesktop ? (
              <Bevel>
                Converting to permanent coverage may be right for you if you…
              </Bevel>
            ) : (
              <>
                <Bevel>Converting to permanent coverage</Bevel>
                <Bevel>may be right for you if you...</Bevel>
              </>
            )}
          </Box>
          <Box className={Styles.CalloutContainer}>
            <Callout
              svg={<Protection />}
              caption="Want peace of mind knowing your family will always be financially protected"
            />
            <Callout
              svg={<Cash />}
              caption="Want the potential to build cash value that you can access when you need it most"
              superscript={1}
            />
            <Callout
              svg={<Coverage />}
              caption="Want long-term coverage, regardless of age or health"
              superscript={2}
            />
          </Box>
          <WLText className={Styles.Text}>
            With your term life policy, you have the option to convert some or
            all of it to a permanent policy. When you convert, you won’t need to
            undergo another medical exam, as long as you complete it by your
            final conversion date. And if you want to keep some of your term
            life insurance, you can partially convert it without losing the
            remainder of your coverage.
            <sup style={{ fontWeight: 'regular', fontSize: 'small' }}>3</sup>
          </WLText>
        </Box>
        <Form
          hero={
            <StaticImage
              src="../../../../static/convert/img/term-convert-homepage-promo-612x447.jpg"
              alt="An expecting mother and father sitting beside a lake with their young daughter"
              placeholder="blurred"
              layout="constrained"
              transformOptions={{
                fit: 'cover',
                cropFocus: 'attention'
              }}
            />
          }
          paragraph1={
            <>
              Learn more about the difference between <span>term life</span>,{' '}
              <span>whole life</span> and <span>universal life</span> so you can
              make the right decision for your family’s financial future.
            </>
          }
          paragraph2={
            <>
              Fill out the form and your WoodmenLife Representative will contact
              you and help you determine what is best for your family’s
              financial future.
            </>
          }
          refPage="Convert"
          subRef="convert"
        />
        <ol className={Styles.DisclosureList}>
          <WLH3>Disclosures</WLH3>
          <li>
            <p>
              Loans against the cash value of your certificate will accrue
              interest, reduce the death benefit and reduce available cash
              value. A loan, withdrawal or surrender may be a taxable event. For
              tax advice, consult with your professional tax advisor. Excessive
              loans or withdrawals may cause the policy to lapse.
            </p>
            <p>
              If you are under age 59½ at the time of any withdrawal a 10% IRS
              penalty tax may apply.
            </p>
            <p>
              The No Lapse Universal Life product generally does not build cash
              value, but offers lower premium rates and guarantees an
              individual’s life insurance coverage through a certain age.
            </p>
          </li>
          <li>
            <p>This does not apply to the indexed universal life product.</p>
            <p>
              These certificates have exceptions, limitations and restrictions.
              For cost and complete details in coverage, contact your
              WoodmenLife Representative. Forms: ICC10 8205 3-10, 8205 3-10
              (XX), O-8205 3-10 (XX), ICC17 8678 1-17; 8678 1-17 (XX), ICC17
              8679 1-17; 8679 1-17 (XX), ICC18 8730 4-18, 8730 4-18 (XX).
            </p>
            <p>All products may not be available in all states.</p>
            <p>
              This is a solicitation of insurance and an agent may contact you.
            </p>
          </li>
          <li>
            <p>
              For partial conversion a remaining minimum face amount may be
              required to maintain preferred rating status and/or term coverage.
            </p>
          </li>
        </ol>
      </WLContainer>
      <WLContainer width="WIDE" align="start" paddingX={{ base: 3, xl: 0 }}>
        <PageIdentifier webNumber={'LP1'} lastUpdated={'9/2022'} />
      </WLContainer>
    </Layout>
  );
};

export default ConvertPage;
